import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Pagination } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { CheckOutlined, CloseOutlined } from "@mui/icons-material";
import EnhancedTableHead from "./tabla/EnhancedTableHead";
import EnhancedTableToolbar from "./tabla/EnhancedTableToolbar";

export default function EnhancedTable({
  rows,
  registros,
  handleChangePage,
  handleRequestSort,
  page,
  bloque,
  totPages,
  filtro,
  setFiltro,
  consulta,
  setConsulta,
}) {
  const [selected, setSelected] = useState({});

  const handleClick = (event, item) => {
    item.cliente = item.cliente
      .replace("Ñ", "N")
      .replace("ñ", "n")
      .replace("Ã?", "N");
    /////asigna de a uno
    if (selected.cdc === item.cdc) {
      setSelected({});
    } else {
      setSelected(item);
    }
  };

  const style = {
    width: "50%",
    // .mitabladet {
    //   word-wrap: break-word;
    //   table-layout: fixed;
    //   white-space: nowrap;
    // }
  };

  const isSelected = (cdc) => selected.cdc === cdc;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * 8 - rows.length) : 0;

  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.cdc === undefined ? 0 : 1}
          selected={selected}
          consulta={consulta}
          setConsulta={setConsulta}
          setFiltro={setFiltro}
        />
        <TableContainer
          sx={{
            maxHeight:
              windowSize.innerHeight <= 768 ? "calc(55vh)" : "calc(75vh)",
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length === 0 ? 0 : 1}
              order={filtro.tipOrden}
              orderBy={filtro.orden}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              loading={rows.length === 0}
            />

            <TableBody>
              {rows.map((row, index) => {
                if (
                  index < (page - (bloque - 1) * 8) * 8 &&
                  index >= (page - (bloque - 1) * 8 - 1) * 8
                ) {
                  const isItemSelected = isSelected(row.cdc);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.cdc + index}
                      selected={isItemSelected}
                      style={{
                        whiteSpace: "nowrap",
                        tableLayout: "fixed",
                        wordWrap: "break-word",
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">{row.comprobante}</TableCell>
                      <TableCell align="left">{row.fecha}</TableCell>
                      <TableCell align="left" width={350}>
                        {row.cliente}
                      </TableCell>
                      <TableCell align="left">{row.numero}</TableCell>
                      <TableCell align="left">{row.moneda}</TableCell>
                      <TableCell align="right">{row.total}</TableCell>
                      <TableCell align="left">{row.estado}</TableCell>
                      <TableCell align="left">
                        {row.envioMail ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          <CloseOutlined style={{ color: "red" }} />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          color="primary"
          onChange={handleChangePage}
          // count={calcPages()}
          count={totPages}
          page={page}
          style={{
            bottom: "4%",
            position: "fixed",
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
          }}
        />
      </Paper>
    </Box>
  );
}
