import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import Img from "./Img";

const PanelKude = (props) => {
  const { configuracion, setConfiguracion, selected, setSelected } = props;

  //Obtiene el valor del radio button seleccionado
  // const [selected, setSelected] = useState(parseFloat(configuracion.logo));

  //Recogiendo el valor del radiobutton con useNavigate
  const navigate = useNavigate();

  //Manejo de seleccion del radiobutton
  const handleChange = (e) => {
    setSelected(e.target.value);
    setConfiguracion({
      ...configuracion,
      kude: e.target.value,
    });
  };

  //Persistencia del localstorage
  // useEffect(() => {
  //   const localSelected = JSON.parse(localStorage.getItem("selected"));
  //   setSelected(localSelected);
  // }, []);

  //Guardando en el localstorage
  // useEffect(() => {
  //   navigate("/configuracion", { state: { selected } });
  //   localStorage.setItem("selected", JSON.stringify(selected));
  // }, [selected]);

  const stylePad = {
    padding: "2% 2% 1% 2%",
    margin: "1% 1% 0% 0%",
    // border: '1px solid',
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%)",
    borderRadius: "10px",
    backgroundColor: "#fff",
  };

  function reducer(state, action) {}

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ ...stylePad }}>
            <SimpleBar forceVisible="false" style={{ maxHeight: 510 }}>
              <div>
                <Grid sx={{ height: 340, width: 350 }}>
                  <label className="cinta">
                    <input
                      type="radio"
                      name="kude"
                      value="2"
                      checked={selected === "2"}
                      onChange={handleChange}
                    />
                    <Img imagen="cinta.png" />
                  </label>
                </Grid>
              </div>
              <div style={{ margin: 350, marginTop: -340 }}>
                <Grid sx={{ height: 340, width: 350 }}>
                  <label className="cintaRes">
                    <input
                      type="radio"
                      name="kude"
                      value="3"
                      checked={selected === "3"}
                      onChange={handleChange}
                    />
                    <Img imagen="cintaRes.png" />
                  </label>
                </Grid>
              </div>
              <div style={{ margin: 700, marginTop: -690 }}>
                <Grid sx={{ height: 500, width: 460 }}>
                  <label className="facEsta">
                    <input
                      type="radio"
                      name="kude"
                      value="1"
                      checked={selected === "1"}
                      onChange={handleChange}
                    />
                    <Img imagen="facEsta.png" />
                  </label>
                </Grid>
              </div>
            </SimpleBar>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PanelKude;
