import React, { useState, useEffect, useRef } from "react";
import Menu from "../principal/menu/Menu";
import PropTypes from "prop-types";
import { Grid, Typography, Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../../css/header.css";
import { getSession } from "../../apis/Session";
import PanelConfig from "./PanelConfig";
import PanelKude from "./PanelKude";
import {
  getConfiguracion,
  subirCert,
  subirDatos,
  subirkude,
  subirLogo,
  getVencimiento,
} from "../../apis/api";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <React.Fragment>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </React.Fragment>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tab-${index}`,
  };
}

const Configuracion = () => {
  const style = {
    width: "80%",
    height: "calc(100vh)",
    overflowY: "scroll",
  };

  const [valueTab, setValueTab] = React.useState(0);
  const handleChangeTabs = (event, newValue) => {
    if (newValue === 1) {
      setInputLogo(document.getElementById("logo"));
    }
    setValueTab(newValue);
  };

  const stylePad = {
    // padding: "1% 2% 1% 2%",
    margin: "1% 1% 0% 0%",
    // border: '1px solid',
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%)",
    borderRadius: "10px",
    backgroundColor: "#fff",
  };

  const [configuracion, setConfiguracion] = useState(confIni);
  const [imagenlogo, setImagenlogo] = useState("imgLogo");
  const [inputcert, setInputCert] = useState("");
  const [inputlogo, setInputLogo] = useState("");
  const [sucursal, setSucursal] = useState("001");
  const [expedicion, setExpedicion] = useState("001");

  const ChangeImgFile = (ev) => {
    let reader = new FileReader();
    if (ev.target.name === "logo1") {
      reader.onload = function (e) {
        document.getElementById("imgLogo").src = e.target.result;
      };
      reader.readAsDataURL(ev.target.files[0]);
    }
    subirLogo(ev.target.files[0], sucursal, expedicion);
  };

  const handleChangeUbi = (ev) => {
    setExpedicion(ev.target.value);
  };

  const handleChangeSuc = (ev) => {
    setSucursal(ev.target.value);
  };

  async function grabarConfiguracion() {
    configuracion.ruc = getSession().ruc;
    configuracion.logo = "si";
    if (configuracion.mailhost === undefined) configuracion.mailhost = "";
    if (configuracion.mailport === undefined) configuracion.mailport = "";
    if (inputcert.value !== undefined && inputcert.value !== "") {
      configuracion.certificado = "si";
    } else {
      if (
        configuracion.aliasCert === "" ||
        configuracion.aliasCert === undefined
      ) {
        configuracion.certificado = "no";
      } else {
        configuracion.certificado = "si";
      }
    }
    if (imagenlogo !== "") {
      configuracion.logo = "si";
    } else {
      configuracion.logo = "no";
    }
    console.log(configuracion);
    let res = await subirDatos(configuracion);
    if (res === "si") {
      handleChangeTabs(null, 0);
    }
    return configuracion;
  }

  const handleChange = (event) => {
    switch (event.target.name) {
      case "server":
        if (
          event.target.value ===
          "https://ekuatia.set.gov.py/consultas-test/qr?nVersion="
        ) {
          setConfiguracion({
            ...configuracion,
            server: event.target.value,
            tipserv: "SERVIDOR DE PRUEBAS",
            ws_server: "https://sifen-test.set.gov.py/de/ws/",
          });
        } else {
          setConfiguracion({
            ...configuracion,
            server: event.target.value,
            tipserv: "SERVIDOR DE PRODUCCION",
            ws_server: "https://sifen.set.gov.py/de/ws/",
          });
        }
        break;
      case "protocol":
        setConfiguracion({
          ...configuracion,
          protocol: event.target.value,
        });
        break;
      case "fallback":
        setConfiguracion({
          ...configuracion,
          fallback: event.target.value,
        });
        break;
      case "starttls":
        setConfiguracion({
          ...configuracion,
          starttls: event.target.value,
        });
        break;
      case "ssl":
        setConfiguracion({
          ...configuracion,
          ssl: event.target.value,
        });
        break;
      default:
        setConfiguracion({
          ...configuracion,
          [event.target.name]: event.target.value,
        });
    }
  };

  const [selected, setSelected] = useState();

  useEffect(() => {
    (async () => {
      let config = await getConfiguracion();
      if (config.vencimiento === null || config.vencimiento === undefined) {
        let vnc = await getVencimiento();
        config.vencimiento =
          vnc === undefined || vnc.length == 0
            ? "1900-01-01"
            : vnc[0].fechaproceso;
      }
      setConfiguracion(config);
      setTimeout(() => {
        setSelected(config.kude);
      }, 500);
    })();
  }, []);

  return (
    <div>
      <Box sx={{ display: "flex" }} overflow="hidden">
        <Menu />
        <div style={style}>
          <div style={{ ...stylePad, textAlign: "left" }}>
            <Grid container>
              <Grid item xs={6}>
                <Tabs
                  onChange={handleChangeTabs}
                  value={valueTab}
                  aria-label="Tabs where selection follows focus"
                  selectionFollowsFocus
                >
                  <Tab label="Datos" {...a11yProps(0)} />
                  <Tab label="Kude" {...a11yProps(1)} />
                </Tabs>
              </Grid>
              <Grid item xs={6}>
                <h1
                  style={{
                    textAlign: "right",
                    color: "rgb(212 212 219)",
                    marginBottom: "0",
                    marginTop: "0",
                  }}
                >
                  Configuración
                </h1>
              </Grid>
              <Grid item xs={12}>
                <TabPanel value={valueTab} index={0}>
                  <PanelConfig
                    configuracion={configuracion}
                    setConfiguracion={setConfiguracion}
                    ChangeImgFile={ChangeImgFile}
                    grabarConfiguracion={grabarConfiguracion}
                    handleChange={handleChange}
                    setInputCert={setInputCert}
                    inputcert={inputcert}
                    setImagenlogo={setImagenlogo}
                    handleChangeUbi={handleChangeUbi}
                    handleChangeSuc={handleChangeSuc}
                    sucs={sucursal}
                    exps={expedicion}
                  />
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                  <PanelKude
                    configuracion={configuracion}
                    setConfiguracion={setConfiguracion}
                    selected={selected}
                    setSelected={setSelected}
                    // handleChangeCheck={handleChangeCheck}
                  />
                </TabPanel>
              </Grid>
              <Grid item xs={12}>
                <React.Fragment>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "1%",
                      marginRight: "1%",
                    }}
                  >
                    {valueTab === 0 ? (
                      <Button
                        variant="contained"
                        sx={{ mt: 3, ml: 1 }}
                        onClick={(e) => handleChangeTabs(e, 1)}
                      >
                        Siguiente
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ mt: 3, ml: 1 }}
                        onClick={grabarConfiguracion}
                      >
                        CONFIRMAR
                      </Button>
                    )}
                  </Box>
                </React.Fragment>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid></Grid>
      </Box>
    </div>
  );
};

export default Configuracion;

const confIni = {
  ruc: "",
  certificado: "",
  idCsc: "",
  csc: "",
  usuCert: "",
  passCert: "",
  aliasCert: "",
  logo: "",
  mailurl: "https://factemail.facturafacil.com.py/enviar",
  mailhost: "",
  mailport: "",
  mailport2: "",
  protocol: "",
  fallback: "",
  starttls: "",
  ssl: "",
  mail: "",
  mailpass: "",
  mailsubject: "",
  mailbody: "",
  server: "",
  tipserv: "",
  ws_server: "",
  timbrado: "",
  establecimiento: "",
  expedicion: "",
  vigenciaini: "",
  kude: "",
  vencimiento: "",
};
